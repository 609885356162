exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[category]-category-js": () => import("./../../../src/pages/category/[category].js" /* webpackChunkName: "component---src-pages-[category]-category-js" */),
  "component---src-pages-[page]-jsx": () => import("./../../../src/pages/[page].jsx" /* webpackChunkName: "component---src-pages-[page]-jsx" */),
  "component---src-pages-[tag]-tag-js": () => import("./../../../src/pages/tag/[tag].js" /* webpackChunkName: "component---src-pages-[tag]-tag-js" */),
  "component---src-pages-articles-[id]-js": () => import("./../../../src/pages/articles/[id].js" /* webpackChunkName: "component---src-pages-articles-[id]-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

